import { useLocation, useNavigate } from "react-router-dom";
import {
  BottomNavbarIcons,
  BottomNavbarIconVariants,
} from "./BottomNavbar.data";
import { useGetUser } from "../../store/selectors/userSelector";

/**
 * A hook which contains the business logic needed for the BottomNavbar component.
 *
 * @returns the icon filepaths needed as well as a function to be executed when an icon is clicked
 */
const useBottomNavbar = () => {
  const location = useLocation();
  const { userId } = useGetUser();
  const navigate = useNavigate();

  let gridIconVariant = BottomNavbarIconVariants.FILLED;
  let userIconVariant = BottomNavbarIconVariants.OUTLINED;
  if (location.pathname.includes("profile")) {
    // on someone's profile, swap the icons
    gridIconVariant = BottomNavbarIconVariants.OUTLINED;
    userIconVariant = BottomNavbarIconVariants.FILLED;
  }

  const gridIconSrc =
    gridIconVariant === BottomNavbarIconVariants.FILLED
      ? "/icons/grid_filled.png"
      : "/icons/grid_outlined.png";
  const userIconSrc =
    userIconVariant === BottomNavbarIconVariants.FILLED
      ? "/icons/user_filled.png"
      : "/icons/user_outlined.png";

  const onIconClick = (icon: BottomNavbarIcons) => {
    if (icon === BottomNavbarIcons.GRID) {
      // navigate to home page
      navigate("/home");
    } else {
      // navigate to profile page
      navigate(`/profile/${userId ?? 0}`);
    }
  };

  return { gridIconSrc, userIconSrc, onIconClick };
};

export default useBottomNavbar;
