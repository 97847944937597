import { SxProps, Theme } from "@mui/material";
import Constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 2,
  },
  profileCard: {
    width: "100%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    p: 2,
    alignItems: "center",
    gap: 1,
    borderRadius: 1,
  },
  dummyAvatar: {
    width: "100%",
    maxWidth: 75,
    aspectRatio: 1,
    borderRadius: "50%",
    backgroundColor: "lightgray",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    backgroundColor: Constants.GREEN,
    border: 1,
    p: 1,
    "&:hover": { cursor: "pointer" },
    borderRadius: 2,
    color: "white",
    width: "fit-content",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
};

export default styles;
