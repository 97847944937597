import { SxProps, Theme } from "@mui/material";
import Constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  text: {},
  buttonRowContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    gap: 2,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    backgroundColor: Constants.GREEN,
    border: 1,
    p: 1,
    "&:hover": { cursor: "pointer" },
    borderRadius: 2,
    color: "white",
  },
  buttonText: {
    fontSize: 12,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  picture: {
    width: "100%",
  },
  imageContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
};

export default styles;
