import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./styles";
import { isValidForm, SignUpFormProps } from "./SignUpForm.data";
import constants from "../../theme/constants";
import { modifyForm } from "../../utils/utils";
import useSignUpForm from "./SignUpForm.hooks";

/**
 * The user signup form component.
 *
 * @returns the element containing the signup form.
 */
const SignUpForm = ({ onSwitchFormType }: SignUpFormProps): JSX.Element => {
  const { form, setForm, onSubmit } = useSignUpForm();

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" sx={styles.titleText}>
        {"Create Profile"}
      </Typography>
      <TextField
        label="Name"
        variant="outlined"
        size={"small"}
        value={form?.name}
        onChange={(e) => modifyForm(setForm, { name: e.target.value })}
      />
      <TextField
        label="Username"
        variant="outlined"
        size={"small"}
        value={form?.username}
        onChange={(e) => modifyForm(setForm, { username: e.target.value })}
      />
      <TextField
        label="Password"
        variant="outlined"
        size={"small"}
        type="password"
        value={form?.password}
        onChange={(e) => modifyForm(setForm, { password: e.target.value })}
      />
      <TextField
        label="Phone Number"
        variant="outlined"
        size={"small"}
        type="tel"
        value={form?.phoneNumber}
        onChange={(e) => modifyForm(setForm, { phoneNumber: e.target.value })}
      />
      <FormControl fullWidth size={"small"}>
        <InputLabel>{"Account Type"}</InputLabel>
        <Select
          value={form?.userType}
          label="Age"
          onChange={(e) => {
            modifyForm(setForm, {
              userType: e.target.value as "supplier" | "worker" | undefined,
            });
          }}
        >
          <MenuItem value={undefined}>{"Unselected"}</MenuItem>
          <MenuItem value={"worker"}>{"Worker"}</MenuItem>
          <MenuItem value={"supplier"}>{"Supplier"}</MenuItem>
        </Select>
      </FormControl>
      <Box
        onClick={onSubmit}
        sx={{
          ...styles.button,
          "&:hover": { cursor: isValidForm(form) ? "pointer" : "default" },
          backgroundColor: isValidForm(form) ? constants.GREEN : "lightgray",
        }}
      >
        <Typography>{"Sign Up"}</Typography>
      </Box>
      <Typography onClick={onSwitchFormType} sx={styles.bottomText}>
        {"Already have an account?"}
      </Typography>
    </Box>
  );
};

export default SignUpForm;
