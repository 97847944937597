import { Box, CircularProgress, Typography } from "@mui/material";
import styles from "./styles";

/**
 * This component is to display a loading message and spinner.
 *
 * @returns the element representing this component
 */
const LoadingSpinner = (): JSX.Element => {
  return (
    <Box sx={styles.container}>
      <Typography variant={"h5"}>{"Loading Profile Information"}</Typography>
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
