import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { WorkerTaskStatusProps } from "./WorkerTaskStatus.data";
import styles from "./styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getReadableTaskStatus } from "../../utils/utils";
import constants from "../../theme/constants";
import useWorkerTaskStatus from "./WorkerTaskStatus.hooks";
import ImageUploader from "../ImageUploader";
import { Link } from "react-router-dom";
import PDFViewer from "../PDFViewer";

const WorkerTaskStatus = ({
  taskId,
  division,
  cost,
  status,
  duration,
  pictures,
  dueDate,
  task,
  address,
  trainingUrls,
  surveyQuestionsUrl,
  supplierId,
  workerId,
}: WorkerTaskStatusProps): JSX.Element => {
  const {
    onAcceptTask,
    onCancelTask,
    onCompleteTask,
    onViewSupplierProfile,
    images,
    setImages,
    isMyTask,
    canSubmitTask,
  } = useWorkerTaskStatus(taskId, workerId, supplierId);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.informationContainer}>
        <Box sx={styles.headerRow}>
          <Typography variant={"h5"} sx={styles.informationText}>
            {division}
          </Typography>
          <Box sx={styles.button}>
            <Typography onClick={onViewSupplierProfile}>
              {"View Supplier Profile"}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant={"h6"}
          sx={{ ...styles.informationText, color: constants.GREEN }}
        >
          {getReadableTaskStatus(status)}
        </Typography>
        <Typography
          variant={"h6"}
          sx={styles.informationText}
        >{`Price: $${cost}`}</Typography>
        <Typography variant={"h6"} sx={styles.informationText}>
          {`Address: ${address}`}
        </Typography>
        <Typography
          variant={"h6"}
          sx={styles.informationText}
        >{`Task: ${task}`}</Typography>
        <Typography
          variant={"h6"}
          sx={styles.informationText}
        >{`Duration: ${duration} hours`}</Typography>
        <Typography
          variant={"h6"}
          sx={styles.informationText}
        >{`Due Date: ${dueDate}`}</Typography>
        <Link to={surveyQuestionsUrl} target={"_blank"}>
          <Typography variant={"h6"} sx={styles.informationText}>
            {"Survey Questions"}
          </Typography>
        </Link>
        {trainingUrls.map((url, index) => (
          <Accordion key={`instructions-accordion-${index}`}>
            <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
              <Typography>{`Instructions PDF ${index + 1}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PDFViewer url={url} />
            </AccordionDetails>
          </Accordion>
        ))}

        {isMyTask && status === "in_progress" && (
          <ImageUploader images={images} setImages={setImages} />
        )}
      </Box>
      {isMyTask ? (
        status === "in_progress" ? (
          <Box sx={styles.buttonRowContainer}>
            <Box sx={styles.button} onClick={onCancelTask}>
              <Typography sx={styles.buttonText}>{"Cancel Task"}</Typography>
            </Box>
            <Box
              sx={{
                ...styles.button,
                backgroundColor: canSubmitTask ? constants.GREEN : "lightgray",
                cursor: canSubmitTask ? "pointer" : "default",
              }}
              onClick={onCompleteTask}
            >
              <Typography sx={styles.buttonText}>{"Complete Task"}</Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.accordionColumnContainer}>
            <Accordion>
              <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                <Typography>{`Submitted Pictures`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {pictures.map((picture, index) => {
                  return (
                    <Box
                      component={"img"}
                      src={picture}
                      key={`picture-${index}`}
                      sx={styles.picture}
                    />
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      ) : (
        <Box sx={styles.button}>
          <Typography onClick={onAcceptTask} sx={styles.buttonText}>
            {"Accept Task"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default WorkerTaskStatus;
