import { createTheme } from "@mui/material/styles";
import Constants from "./constants";

const theme = createTheme({
  palette: {
    text: {
      primary: Constants.PURPLE,
    },
    primary: {
      main: Constants.PURPLE,
      light: Constants.LIGHT_PURPLE,
    },
  },
});

export default theme;
