import { Document, Page, pdfjs } from "react-pdf";
import { PDFViewerProps } from "./PDFViewer.data";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles";
import constants from "../../theme/constants";

// need to add pollyfill because of node version mismatch
// https://github.com/wojtekmaj/react-pdf/issues/1811#issuecomment-2157866061
// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === "undefined") {
  if (window)
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
}

// use cdn (legacy)
// https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#use-external-cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

/**
 * This component allows the user to view a PDF document.
 *
 * @returns the element representing this component
 */
const PDFViewer = ({ url }: PDFViewerProps): JSX.Element => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const atFirstPage = pageNumber === 1;
  const atLastPage = pageNumber === numPages;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const onPreviousPage = () => {
    setPageNumber((p) => Math.max(p - 1, 1));
  };

  const onNextPage = () => {
    setPageNumber((p) => Math.min(numPages ?? 1, p + 1));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.buttonRow}>
        <Box
          sx={{
            ...styles.button,
            backgroundColor: atFirstPage ? "lightgray" : constants.GREEN,
          }}
          onClick={onPreviousPage}
        >
          <Typography>{"Previous Page"}</Typography>
        </Box>
        <Box
          sx={{
            ...styles.button,
            backgroundColor: atLastPage ? "lightgray" : constants.GREEN,
          }}
          onClick={onNextPage}
        >
          <Typography>{"Next Page"}</Typography>
        </Box>
      </Box>
      <Document
        file={{ url }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => {
          alert("There was an error loading the instructions pdf.");
          console.log(error.message);
        }}
      >
        <Page pageNumber={pageNumber} width={200} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </Box>
  );
};

export default PDFViewer;
