import { Box, Typography } from "@mui/material";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../../store/selectors/userSelector";
import { useEffect, useState } from "react";
import { request } from "../../api/utils";
import { TaskCardProps } from "../../components/TaskCard/TaskCard.data";
import TaskCardContainer from "../../components/TaskCardContainer";

/**
 * This page is displayed when a user (who is a supplier) is viewing the tasks that they have created
 * (and are not yet complete).
 *
 * @returns the element representing this page
 */
const SupplierTaskPage = (): JSX.Element => {
  const { userId } = useGetUser();
  const [tasks, setTasks] = useState<TaskCardProps[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserTasks = async () => {
      const tasks = (await request({
        requestType: "GET",
        endpoint: `/user/tasks`,
        queryParams: { userId: userId! },
      })) as any[];

      setTasks(
        tasks
          .filter((task) => task.status !== "completed")
          .map((task) => {
            return { ...task, taskId: task._id };
          })
      );
    };

    void fetchUserTasks();
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography variant={"h5"}>{"Your Tasks"}</Typography>
        <Box onClick={() => navigate("/tasks/create")} sx={styles.button}>
          <Typography>{"Create New Task"}</Typography>
        </Box>
      </Box>

      <TaskCardContainer taskCardProps={tasks} />
    </Box>
  );
};

export default SupplierTaskPage;
