import axios from "axios";
import { config, environment } from "../config/config";

export type RequestType = "GET" | "POST" | "DELETE" | "PUT";
export type BodyType = number | string | undefined | ArrayBuffer | File;

export type RequestInput = {
  requestType: RequestType;
  endpoint: string;
  queryParams?: Record<string, string>;
  body?: FormData | Record<string, BodyType | Array<BodyType>>;
  headers?: Record<string, string>;
};

/**
 * Sends a request to our backend API. The url depends on the environment that you are executing in.
 *
 * @returns the response from the backend
 */
export const request = async ({
  requestType,
  endpoint,
  queryParams,
  body,
  headers,
}: RequestInput) => {
  const url = `${config[environment].apiBaseUrl}${endpoint}`;

  switch (requestType) {
    case "POST":
      return (await axios.post(url, body, { headers })).data;
    case "GET":
      return (await axios.get(url, { params: queryParams, headers })).data;
    case "DELETE":
      return (await axios.delete(url, { data: body, headers })).data;
    case "PUT":
      return (await axios.put(url, body, { headers })).data;
  }
};
