import { SxProps, Theme } from "@mui/material";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flex: 1,
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};

export default styles;
