// Ideally we would have a development deployment environment too
export enum Environment {
  LOCAL,
  PROD,
}

type Config = {
  apiBaseUrl: string; // the base url for our backend API endpoints
};

export const environment =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? Environment.PROD
    : Environment.LOCAL;

export const config: Record<Environment, Config> = {
  [Environment.LOCAL]: {
    apiBaseUrl: "http://localhost:8000",
  },
  [Environment.PROD]: {
    apiBaseUrl: "https://api.stockswift.co",
  },
};
