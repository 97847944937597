import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    gap: 1,
    border: 1,
    borderColor: "lightgray",
    width: 300,
    p: 4,
    borderRadius: 2,
  },
  titleText: {
    alignSelf: "center",
    mb: 2,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    border: 1,
    p: 1,
    color: "white",
  },
  bottomText: {
    cursor: "pointer",
    alignSelf: "center",
  },
};

export default styles;
