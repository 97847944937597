import { useNavigate } from "react-router-dom";
import { useGetUser } from "../../store/selectors/userSelector";
import { useState } from "react";
import {
  CreateTaskFormData,
  defaultFormData,
  isValidForm,
  MAX_FILE_SIZE,
} from "./CreateTaskForm.data";
import { request } from "../../api/utils";
import { modifyForm } from "../../utils/utils";

/**
 * This hook contains the business logic needed for the CreateTaskForm component.
 */
const useCreateTaskForm = () => {
  const navigate = useNavigate();
  const { userId } = useGetUser();

  // form state
  const [form, setForm] = useState<CreateTaskFormData>(defaultFormData);

  /**
   * Updates the form state when a file is uploaded.
   *
   * @param type which file is being uploaded/modified
   * @param event the event to process
   */
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files === null || files.length === 0) return;

    const pdfs: Uint8Array[] = [];
    try {
      await new Promise<void>((resolve, reject) => {
        for (let ix = 0; ix < files.length; ix++) {
          const file = files.item(ix);
          if (file === null) continue;
          if (file.size > MAX_FILE_SIZE) {
            alert("File size too large");
            reject();
          }
          const fr = new FileReader();
          fr.readAsArrayBuffer(file);
          fr.onload = async function () {
            const blob = new Blob([fr.result as ArrayBuffer]);
            const array = new Uint8Array(await blob.arrayBuffer());
            pdfs.push(array);
            if (pdfs.length === files.length) resolve();
          };
        }
      });

      modifyForm(setForm, { ...form, trainingPdfs: pdfs });
    } catch {}
  };

  /**
   * Executed when the user hits "create task". This method creates a new task in the DB
   * and navigates the user to view the created task.
   */
  const onSubmit = async () => {
    if (isValidForm(form)) {
      try {
        const response = await request({
          requestType: "POST",
          endpoint: "/task",
          body: {
            ...form,
            supplierId: userId!,
          },
        });

        const taskId = response._id;
        navigate(`/tasks/${taskId}`); // view the submitted task afterward
      } catch (e) {
        alert("There was a problem creating the task. Please try again!");
      }
    }
  };

  return { form, setForm, handleFileChange, onSubmit };
};

export default useCreateTaskForm;
