import { Box, Typography } from "@mui/material";
import styles from "./styles";
import CreateTaskForm from "../../components/CreateTaskForm";
import { useNavigate } from "react-router-dom";

/**
 * This page will be displayed when a supplier is creating a new task.
 */
const CreateTaskPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography variant={"h5"}>{"Create Task"}</Typography>
        <Box onClick={() => navigate("/home")} sx={styles.button}>
          <Typography>{"Go Back"}</Typography>
        </Box>
      </Box>
      <CreateTaskForm />
    </Box>
  );
};

export default CreateTaskPage;
