import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles";
import { useGetUser } from "../../store/selectors/userSelector";
import { useEffect, useState } from "react";
import { request } from "../../api/utils";
import { TaskCardProps } from "../../components/TaskCard/TaskCard.data";
import TaskCardContainer from "../../components/TaskCardContainer";
import { useAppDispatch } from "../../store/hooks";
import { resetUser } from "../../store/user";
import LoadingSpinner from "../../components/LoadingSpinner";

/**
 * This page will be displayed when a user is viewing someone's profile.
 * It will contain basic information about the user as well as their active tasks.
 *
 * @returns the component representing this page
 */
const ProfilePage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [tasks, setTasks] = useState<TaskCardProps[]>([]);
  const location = useLocation();
  const { userId: loggedInUserId } = useGetUser();
  const userId = location.pathname.split("/")[2];
  const isViewingOwnProfile = loggedInUserId === userId;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const user = await request({
          requestType: "GET",
          endpoint: `/user`,
          queryParams: { userId },
        });

        setName(user.name);
        setPhoneNumber(user.phoneNumber);
      } catch (e) {
        if (isViewingOwnProfile) {
          alert("Could not load your account details. Logging you out.");
          dispatch(resetUser());
        } else {
          alert("Could not load the user's account details.");
        }
      }
    };

    /**
     * Gets all the tasks that are associated with the user, only if we are viewing
     * the profile of the currently logged in user.
     */
    const fetchUserTasks = async () => {
      if (!isViewingOwnProfile) return;
      const tasks = (await request({
        requestType: "GET",
        endpoint: `/user/tasks`,
        queryParams: { userId },
      })) as any[];

      setTasks(tasks.map((task) => ({ ...task, taskId: task._id })));
    };

    void fetchProfileData();
    void fetchUserTasks();
  }, [location.pathname]);

  /**
   * When the user wants to sign out, we clear the user state from redux.
   */
  const onSignOut = async () => {
    dispatch(resetUser());
  };

  /**
   * Can be fired when a user is viewing someone else's profile page. This will return them
   * back to their own profile.
   */
  const onViewOwnProfile = () => {
    navigate("/profile/" + loggedInUserId);
  };

  if (!name || !phoneNumber || !tasks) {
    return <LoadingSpinner />;
  }

  const activeTasks = tasks.filter((task) => task.status !== "completed");
  const completedTasks = tasks.filter((task) => task.status === "completed");

  return (
    <Box sx={styles.container}>
      <Box sx={styles.profileCard}>
        <Typography variant={"h5"}>{name}</Typography>
        <Typography variant={"h6"}>{`Phone Number: ${phoneNumber}`}</Typography>
      </Box>
      <Box sx={styles.headerContainer}>
        <Typography variant={"h5"}>{"Active Tasks"}</Typography>
        <Box sx={styles.button}>
          <Typography
            onClick={isViewingOwnProfile ? onSignOut : onViewOwnProfile}
          >
            {isViewingOwnProfile ? "Sign Out" : "View My Profile"}
          </Typography>
        </Box>
      </Box>
      {!isViewingOwnProfile && (
        <Typography>{"Cannot view other user's tasks"}</Typography>
      )}
      <TaskCardContainer taskCardProps={activeTasks} />
      <Typography variant={"h5"}>{"Completed Tasks"}</Typography>
      {!isViewingOwnProfile && (
        <Typography>{"Cannot view other user's tasks"}</Typography>
      )}
      <TaskCardContainer taskCardProps={completedTasks} />
    </Box>
  );
};

export default ProfilePage;
