import storage from "redux-persist/lib/storage";

export type UserType = "worker" | "supplier";

export type SliceState = {
  userId: string | undefined; // unique ID for the current user
  name: string | undefined; // the full name for the current user
  phoneNumber: string | undefined; // the phone number of the current user
  role: UserType | undefined; // the account type associated with the curent user
  rating: number | undefined; // the user's rating
};

// these fields will be undefined if we have not yet fetched them from the DB
export const initialState: SliceState = {
  userId: undefined,
  name: undefined,
  phoneNumber: undefined,
  role: undefined,
  rating: undefined,
};

// persist this state across page refreshes in localstorage
export const persistConfig = {
  key: "user",
  storage,
};
