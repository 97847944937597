import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { SupplierTaskStatusProps } from "./SupplierTaskStatus.data";
import styles from "./styles";
import { getReadableTaskStatus } from "../../utils/utils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import useSupplierTaskStatus from "./SupplierTaskStatus.hooks";
import { Link } from "react-router-dom";

/**
 * This component is what a supplier sees when they are viewing a task. It displays
 * the current state of the task, as well as buttons to interact with the task.
 *
 * @returns the element that represents this component
 */
const SupplierTaskStatus = ({
  taskId,
  status,
  workerId,
  surveyQuestionsUrl,
  pictures,
}: SupplierTaskStatusProps): JSX.Element => {
  const {
    onDeleteTask,
    onApproveTask,
    onRequestRedoTask,
    onViewAssignedWorker,
  } = useSupplierTaskStatus(taskId, workerId);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Box sx={styles.headerRow}>
          <Typography variant="h5">{`Task Status`}</Typography>
          {workerId !== undefined && (
            <Box sx={styles.button}>
              <Typography onClick={onViewAssignedWorker}>
                {"View assigned worker"}
              </Typography>
            </Box>
          )}
        </Box>

        <Typography variant="h5" sx={styles.statusText}>
          {getReadableTaskStatus(status)}
        </Typography>

        {status === "awaiting_approval" && (
          <Box sx={styles.accordionColumnContainer}>
            <Accordion>
              <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                <Typography>{`Submitted Pictures`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {pictures.map((picture, index) => {
                  return (
                    <Box
                      component={"img"}
                      src={picture}
                      sx={styles.picture}
                      key={`picture-${index}`}
                      width={"50%"}
                    />
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Link to={surveyQuestionsUrl} target={"_blank"}>
              <Typography variant={"h6"} sx={styles.informationText}>
                {"Survey Questions"}
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
      {status === "open" && (
        <Box onClick={onDeleteTask} sx={styles.button}>
          <Typography sx={styles.buttonText}>{"Delete Task"}</Typography>
        </Box>
      )}
      {status === "awaiting_approval" && (
        <Box sx={styles.buttonRowContainer}>
          <Box onClick={onRequestRedoTask} sx={styles.button}>
            <Typography sx={styles.buttonText}>{"Request Redo"}</Typography>
          </Box>
          <Box onClick={onApproveTask} sx={styles.button}>
            <Typography sx={styles.buttonText}>{"Approve Task"}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupplierTaskStatus;
