import { Box, Typography } from "@mui/material";
import styles from "./styles";
import SignUpForm from "../../components/SignUpForm";
import { LoginPageFormType } from "./LoginPage.data";
import { useState } from "react";
import LoginForm from "../../components/LoginForm";

/**
 * This page is for the user to log in to the website. They can also choose to sign up.
 *
 * @returns the element representing this page.
 */
const LoginPage = (): JSX.Element => {
  const [formType, setFormType] = useState<LoginPageFormType>(
    LoginPageFormType.SIGN_UP
  );

  return (
    <Box sx={styles.container}>
      <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
        {"Welcome to StockSwift"}
      </Typography>
      {formType === LoginPageFormType.SIGN_UP ? (
        <SignUpForm
          onSwitchFormType={() => setFormType(LoginPageFormType.LOGIN)}
        />
      ) : (
        <LoginForm
          onSwitchFormType={() => setFormType(LoginPageFormType.SIGN_UP)}
        />
      )}
    </Box>
  );
};

export default LoginPage;
