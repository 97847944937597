// This is all the information that is needed to create a new task
export type CreateTaskFormData = {
  division: string;
  storeNumber: number;
  company: string;
  address: string;
  task: string;
  cost: number;
  duration: number;
  dueDate: string;
  trainingPdfs: Uint8Array[];
  surveyQuestionsUrl: string;
};

// This is the default data values for the form
export const defaultFormData: CreateTaskFormData = {
  division: "",
  storeNumber: 0,
  company: "",
  address: "",
  task: "",
  cost: 0,
  duration: 0,
  dueDate: "",
  trainingPdfs: [],
  surveyQuestionsUrl: "",
};

/**
 * Determines whether a form is ready to be submitted.
 *
 * @param data the form data to be validated
 * @returns true iff the form data is considered valid (i.e. ready to submit)
 */
export const isValidForm = (data: CreateTaskFormData): boolean => {
  return (
    data.division !== "" &&
    data.storeNumber !== 0 &&
    data.company !== "" &&
    data.address !== "" &&
    data.task !== "" &&
    data.cost !== 0 &&
    data.duration !== 0 &&
    data.dueDate !== "" &&
    data.trainingPdfs.length > 0 &&
    data.surveyQuestionsUrl !== ""
  );
};

export const MAX_FILE_SIZE = 1000000; // 1MB
