import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { useState } from "react";
import {
  defaultFormData,
  isValidForm,
  SignUpFormData,
} from "./SignUpForm.data";
import { request } from "../../api/utils";
import { setUser } from "../../store/user";

/**
 * This hook contains the business logic for the SignUpForm component.
 *
 * @returns the resources needed for the SignUpForm component
 */
const useSignUpForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState<SignUpFormData>(defaultFormData);

  const onSubmit = async () => {
    try {
      if (isValidForm(form)) {
        const user = await request({
          requestType: "POST",
          endpoint: "/user",
          body: form,
        });

        dispatch(setUser({ ...user, userId: user._id }));
        navigate("/home");
      }
    } catch (e) {
      alert(
        "There was an error creating your account. There might be another user with the same username."
      );
    }
  };

  return { form, setForm, onSubmit };
};

export default useSignUpForm;
