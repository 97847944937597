import { SxProps, Theme } from "@mui/material";
import Constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: 2,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    backgroundColor: Constants.GREEN,
    border: 1,
    p: 1,
    "&:hover": { cursor: "pointer" },
    borderRadius: 2,
    color: "white",
    width: "fit-content",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default styles;
