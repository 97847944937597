import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, persistConfig, SliceState } from "./data";
import { persistReducer } from "redux-persist";

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<SliceState>) {
      const p = action.payload;
      state.userId = p.userId ?? state.userId;
      state.name = p.name ?? state.name;
      state.phoneNumber = p.phoneNumber ?? state.phoneNumber;
      state.role = p.role ?? state.role;
      state.rating = p.rating ?? state.rating;
    },
    resetUser(state, _action: PayloadAction<void>) {
      state.userId = initialState.userId;
      state.name = initialState.name;
      state.phoneNumber = initialState.phoneNumber;
      state.role = initialState.role;
      state.rating = initialState.rating;
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;
export default persistReducer(persistConfig, userSlice.reducer);
