import { useNavigate } from "react-router-dom";

/**
 * This hook contains the business logic for the TaskCard component.
 *
 * @param taskId the unique ID for this task
 * @param onClick (optional) override the default callback for when this component is clicked/pressed.
 * @returns the resources needed in the TaskCard component
 */
const useTaskCard = (taskId: string, onClick?: () => void) => {
  const navigate = useNavigate();

  const onTaskCardClick = () => {
    if (onClick) {
      onClick();
    }

    navigate(`/tasks/${taskId}`);
  };

  return { onTaskCardClick };
};

export default useTaskCard;
