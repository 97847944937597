import { Box, TextField, Typography } from "@mui/material";
import styles from "./styles";
import { isValidForm } from "./CreateTaskForm.data";
import constants from "../../theme/constants";
import useCreateTaskForm from "./CreateTaskForm.hooks";
import { modifyForm } from "../../utils/utils";

/**
 * This component is a form that suppliers will fill out in order to create a new task.
 *
 * @returns an element that represents this form
 */
const CreateTaskForm = (): JSX.Element => {
  const { form, setForm, onSubmit, handleFileChange } = useCreateTaskForm();

  return (
    <Box sx={styles.container}>
      <TextField
        label="Division"
        variant="outlined"
        sx={styles.input}
        value={form?.division}
        onChange={(e) => modifyForm(setForm, { division: e.target.value })}
      />
      <TextField
        label="Store #"
        variant="outlined"
        type="number"
        sx={styles.input}
        value={form?.storeNumber}
        onChange={(e) =>
          modifyForm(setForm, { storeNumber: parseInt(e.target.value) })
        }
      />
      <TextField
        label="Co"
        variant="outlined"
        sx={styles.input}
        value={form?.company}
        onChange={(e) => modifyForm(setForm, { company: e.target.value })}
      />
      <TextField
        label="Address"
        variant="outlined"
        sx={styles.input}
        value={form?.address}
        onChange={(e) => modifyForm(setForm, { address: e.target.value })}
      />
      <TextField
        label="Task"
        variant="outlined"
        sx={styles.input}
        value={form?.task}
        onChange={(e) => modifyForm(setForm, { task: e.target.value })}
      />
      <TextField
        label="Cost"
        variant="outlined"
        sx={styles.input}
        type="number"
        value={form?.cost}
        onChange={(e) =>
          modifyForm(setForm, { cost: parseInt(e.target.value) })
        }
      />
      <TextField
        label="Duration (hours)"
        variant="outlined"
        sx={styles.input}
        type="number"
        value={form?.duration}
        onChange={(e) =>
          modifyForm(setForm, { duration: parseInt(e.target.value) })
        }
      />
      <TextField
        label="Due Date"
        type={"date"}
        variant="outlined"
        sx={styles.input}
        value={form?.dueDate}
        onChange={(e) => modifyForm(setForm, { dueDate: e.target.value })}
      />
      <TextField
        label="Survey Form URL"
        variant="outlined"
        sx={styles.input}
        value={form?.surveyQuestionsUrl}
        onChange={(e) =>
          modifyForm(setForm, { surveyQuestionsUrl: e.target.value })
        }
      />
      <Typography>
        {"Upload Training Instructions PDF(s) (less than 1MB)"}
      </Typography>
      <input
        type="file"
        accept="application/pdf"
        multiple
        onChange={handleFileChange}
      />
      <Box
        onClick={onSubmit}
        sx={{
          ...styles.button,
          cursor: isValidForm(form) ? "pointer" : "default",
          backgroundColor: isValidForm(form) ? constants.GREEN : "lightgray",
        }}
      >
        <Typography>{"Create New Task"}</Typography>
      </Box>
    </Box>
  );
};

export default CreateTaskForm;
