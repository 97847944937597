import { Box, Typography } from "@mui/material";
import styles from "./styles";
import { useEffect, useState } from "react";
import { TaskCardProps } from "../../components/TaskCard/TaskCard.data";
import { request } from "../../api/utils";
import TaskCardContainer from "../../components/TaskCardContainer";

/**
 * This page will be displayed when a worker is looking for new tasks to complete.
 *
 * @returns the component representing this page
 */
const OpenTasksPage = (): JSX.Element => {
  const [tasks, setTasks] = useState<TaskCardProps[]>([]);

  useEffect(() => {
    const fetchOpenTasks = async () => {
      const tasks = (await request({
        requestType: "GET",
        endpoint: "/task/open",
      })) as any[];

      setTasks(
        tasks
          .filter((task) => task.status !== "completed")
          .map((task) => ({ ...task, taskId: task._id }))
      );
    };

    fetchOpenTasks();
  }, []);

  return (
    <Box sx={styles.container}>
      <Typography variant={"h5"}>{"Tasks Available"}</Typography>
      <TaskCardContainer taskCardProps={tasks} />
    </Box>
  );
};

export default OpenTasksPage;
