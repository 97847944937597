import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { useState } from "react";
import { defaultFormData, isValidForm, LoginFormData } from "./LoginForm.data";
import { request } from "../../api/utils";
import { setUser } from "../../store/user";

/**
 * This hook contains the business logic needed for the LoginForm component.
 *
 * @returns the necessary resources for the LoginForm component
 */
const useLoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState<LoginFormData>(defaultFormData);

  /**
   * This method is called when the user is submitting their login credentials.
   */
  const onSubmit = async () => {
    if (!isValidForm(form)) return;

    try {
      const response = await request({
        requestType: "GET",
        endpoint: "/login",
        queryParams: form,
      });

      // once the user logs in, save their information into Redux
      dispatch(setUser({ ...response, userId: response._id }));

      // send the user to the home page
      navigate("/home");
    } catch (e) {
      alert("Could not log you in. Please check your credentials are correct.");
    }
  };

  return { form, setForm, onSubmit };
};

export default useLoginForm;
