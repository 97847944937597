import { useNavigate } from "react-router-dom";
import { useGetUser } from "../../store/selectors/userSelector";
import { useState } from "react";
import { ImageListType } from "react-images-uploading";
import { request } from "../../api/utils";

/**
 * This hook contains the business logic for the WorkerTaskStatus component.
 *
 * @param taskId the unique ID for the task object
 * @param workerId the unique ID for the user who has been assigned to complete this task
 * @param supplierId the unique ID for the user who has created this task
 * @returns the resources needed by the WorkerTaskStatus component
 */
const useWorkerTaskStatus = (
  taskId: string,
  workerId: string | undefined,
  supplierId: string
) => {
  const { userId } = useGetUser();
  const navigate = useNavigate();

  const [images, setImages] = useState<ImageListType>([]);
  const isMyTask = workerId === userId;
  const canSubmitTask = images.length > 0;

  /**
   * This method is fired by a user (who is a worker), which marks the task
   * as "in_progress" and will be fulfilled by the user.
   */
  const onAcceptTask = async () => {
    await request({
      requestType: "PUT",
      endpoint: `/task/accept`,
      body: {
        taskId: taskId,
        userId,
      },
    });

    // pull the latest information about the task
    window.location.reload();
  };

  /**
   * This method cancels the execution of a task. This means that the worker
   * no longer wants to complete this task and will mark the task with a status of
   * "open".
   */
  const onCancelTask = async () => {
    await request({
      requestType: "PUT",
      endpoint: `/task/cancel`,
      body: {
        taskId,
        userId,
      },
    });

    // Go home to see more available tasks.
    navigate(`/home`);
  };

  /**
   * This method marks a task as completed by the worker. This will put the task into
   * "awaiting_approval" for the supplier to approve or request a redo.
   */
  const onCompleteTask = async () => {
    if (!canSubmitTask) return;

    // turn the file in an arrayBuffer to be turned into a Uint8Array to send to the backend
    const pictures = (
      await Promise.all(
        images
          .filter((image) => image.file)
          .map(async (image) => image.file!.arrayBuffer())
      )
    ).map(
      (picture) =>
        new Blob([new Uint8Array(picture)], {
          type: "application/octet-stream",
        })
    );

    // Create a FormData object
    const formData = new FormData();

    // // Append each image as a Blob to the FormData object
    pictures.forEach((blob, index) => {
      formData.append(`images`, blob, `image${index}.png`);
    });

    // Append other fields
    formData.append("userId", userId!);
    formData.append("taskId", taskId);

    try {
      await request({
        requestType: "PUT",
        endpoint: "/task/complete",
        body: formData,
      });

      // Pull in the latest task infromation
      window.location.reload();
    } catch (e) {
      alert(
        "There was an error completing the task. It's likely that the images were too large. Try submitting fewer images and/or smaller images."
      );
      console.log(e);
    }
  };

  /**
   * Allows the user to view the profile of the supplier of this task.
   */
  const onViewSupplierProfile = () => {
    navigate("/profile/" + supplierId);
  };

  return {
    images,
    isMyTask,
    canSubmitTask,
    setImages,
    onAcceptTask,
    onCancelTask,
    onCompleteTask,
    onViewSupplierProfile,
  };
};

export default useWorkerTaskStatus;
