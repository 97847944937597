import { Box, Typography } from "@mui/material";
import styles from "./styles";
import { TaskCardProps } from "./TaskCard.data";
import { useNavigate } from "react-router-dom";
import { getReadableTaskStatus } from "../../utils/utils";
import useTaskCard from "./TaskCard.hooks";

/**
 * This component renders a card for a given task which displays various metadata related to a task.
 *
 * @returns the element that represents this component
 */
const TaskCard = ({
  division,
  taskId,
  dueDate,
  task,
  status,
  storeNumber,
  onClick,
}: TaskCardProps): JSX.Element => {
  const { onTaskCardClick } = useTaskCard(taskId, onClick);

  return (
    <Box sx={styles.container} onClick={onTaskCardClick}>
      <Typography>{division}</Typography>
      <Typography>{`Due Date: ${dueDate}`}</Typography>
      <Typography>{`Task: ${task.substring(0, 20)}`}</Typography>
      <Typography>{`Status: ${getReadableTaskStatus(status)}`}</Typography>
      <Typography>{`Store Number: ${storeNumber}`}</Typography>
    </Box>
  );
};

export default TaskCard;
