import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: Constants.BACKGROUND_COLOR,
    width: "100vw",
    maxWidth: "100vw",
    height: "100dvh",
    maxHeight: "100dvh",
  },
  childContainer: {
    display: "flex",
    width: 0,
    height: 0,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "auto",
    p: 5,
  },
};

export default styles;
