import { SxProps, Theme } from "@mui/material";
import Constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    backgroundColor: Constants.GREEN,
    border: 1,
    p: 1,
    "&:hover": { cursor: "pointer" },
    borderRadius: 2,
    color: "white",
  },
};

export default styles;
