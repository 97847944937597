/**
 * Turns the status from the DB into a more readable form.
 *
 * @param status the status to transform into a readable form.
 * @returns the more readable form. If `status` does not match the names in the DB, then this method
 * will not modify the string.
 */
export const getReadableTaskStatus = (status: string): string => {
  switch (status) {
    case "open":
      return "Open";
    case "in_progress":
      return "In Progress";
    case "awaiting_approval":
      return "Awaiting Approval";
    case "completed":
      return "Completed";
  }
  return status;
};

/**
 * Updates a useState form.
 *
 * @param setForm the setter method to update the form
 * @param toUpdate new values to update the form with. Fields that are not included here will not
 * be updated.
 */
export const modifyForm = <TForm>(
  setForm: React.Dispatch<React.SetStateAction<TForm>>,
  toUpdate: Partial<TForm>
): void => {
  setForm((form) => {
    return {
      ...form,
      ...toUpdate,
    };
  });
};

/**
 * Modifies a useState array
 *
 * @param setArr the setter for the useState array
 * @param newVal the new value to place into the array
 * @param index the index at which to put the new value
 */
export const modifyUseStateArr = <TElement>(
  setArr: React.Dispatch<React.SetStateAction<TElement[]>>,
  newVal: TElement,
  index: number
): void => {
  setArr((arr) => {
    const newArr = [...arr];
    newArr[index] = newVal;
    return newArr;
  });
};
