import { SxProps, Theme } from "@mui/material";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    gap: 1,
  },
};

export default styles;
