import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    alignItems: "center",
    p: 5,
    pt: 3,
    pb: 3,
    color: constants.BORDER_COLOR,
    borderTop: 1,
  },
  icon: {
    "&:hover": { cursor: "pointer" },
  },
};

export default styles;
