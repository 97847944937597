import { isValidForm, LoginFormProps } from "./LoginForm.data";
import { Box, TextField, Typography } from "@mui/material";
import styles from "./styles";
import constants from "../../theme/constants";
import useLoginForm from "./LoginForm.hooks";
import { modifyForm } from "../../utils/utils";

/**
 * The login form for users.
 *
 * @returns the element representing this component.
 */
const LoginForm = ({ onSwitchFormType }: LoginFormProps): JSX.Element => {
  const { form, setForm, onSubmit } = useLoginForm();

  return (
    <Box sx={styles.container}>
      <Typography variant={"h5"} sx={styles.titleText}>
        {"Login"}
      </Typography>
      <TextField
        label="Username"
        variant="outlined"
        size="small"
        value={form?.username}
        onChange={(e) => modifyForm(setForm, { username: e.target.value })}
      />
      <TextField
        label="Password"
        variant="outlined"
        size="small"
        type="password"
        value={form?.password}
        onChange={(e) => modifyForm(setForm, { password: e.target.value })}
      />
      <Box
        onClick={onSubmit}
        sx={{
          ...styles.button,
          "&:hover": { cursor: isValidForm(form) ? "pointer" : "default" },
          backgroundColor: isValidForm(form) ? constants.GREEN : "lightgray",
        }}
      >
        <Typography>{"Login"}</Typography>
      </Box>
      <Typography onClick={onSwitchFormType} sx={styles.bottomText}>
        {"Don't have an account yet?"}
      </Typography>
    </Box>
  );
};

export default LoginForm;
