export type LoginFormData = {
  username: string;
  password: string;
};

export const defaultFormData: LoginFormData = {
  username: "",
  password: "",
};

export const isValidForm = (data: LoginFormData): boolean => {
  if (data.username === "" || data.password === "") {
    return false;
  }

  return true;
};

export type LoginFormProps = {
  onSwitchFormType: () => void;
};
