import { Box } from "@mui/material";
import styles from "./styles";
import { TaskCardContainerProps } from "./TaskCardContainer.data";
import TaskCard from "../TaskCard/TaskCard";

/**
 * This component renders a collection of TaskCards. It arranges them in a series of rows
 * with a small gap between each card.
 *
 * @returns the element that represents this component
 */
const TaskCardContainer = ({
  taskCardProps,
}: TaskCardContainerProps): JSX.Element => {
  return (
    <Box sx={styles.container}>
      {taskCardProps.map((props, ix) => {
        return <TaskCard key={`task-card-${ix}`} {...props} />;
      })}
    </Box>
  );
};

export default TaskCardContainer;
