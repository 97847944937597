import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ProfilePage from "../pages/ProfilePage";
import OpenTasksPage from "../pages/OpenTasksPage";
import CreateTaskPage from "../pages/CreateTaskPage";
import LayoutWrapper from "../wrappers/LayoutWrapper";
import LoginPage from "../pages/LoginPage";
import AuthWrapper from "../wrappers/AuthWrapper/AuthWrapper";
import TaskDescriptionPage from "../pages/TaskDescriptionPage";

type Route = {
  path: string;
  element: JSX.Element;
  requiresAuth: boolean;
};

const routes: Route[] = [
  {
    path: "/home", // Home page.
    element: <HomePage />,
    requiresAuth: true,
  },
  {
    path: "/login", // Login page.
    element: <LoginPage />,
    requiresAuth: false,
  },
  {
    path: "/profile/:userId", // Profile page.
    element: <ProfilePage />,
    requiresAuth: true,
  },
  {
    path: "/tasks/open", // Displays open tasks. Only available to workers.
    element: <OpenTasksPage />,
    requiresAuth: true,
  },
  {
    path: "/tasks/create", // Displays a form to create a new task. Only available to suppliers.
    element: <CreateTaskPage />,
    requiresAuth: true,
  },
  {
    path: "/tasks/:taskId", // Displays the description/status of a given task. UI will depend on whether the user is the supplier or worker.
    element: <TaskDescriptionPage />,
    requiresAuth: true,
  },
  {
    path: "*", // If someone goes to a route that isn't registered, send them to the Home Page.
    element: <HomePage />,
    requiresAuth: true,
  },
];

/**
 * Creates a router for the stockswift application. It wraps the page elements with various wrappers.
 *
 * Wrappers: LayoutWrapper, AuthWrapper
 */
const createStockSwiftRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <LayoutWrapper>
        <Routes>
          {routes.map((route) => {
            let element = route.element;
            if (route.requiresAuth) {
              element = <AuthWrapper>{element}</AuthWrapper>;
            }

            return (
              <Route
                path={route.path}
                key={`route-${route.path}`}
                element={element}
              />
            );
          })}
        </Routes>
      </LayoutWrapper>
    </BrowserRouter>
  );
};

export default createStockSwiftRouter;
