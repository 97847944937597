import { SxProps, Theme } from "@mui/material";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    p: 2,
    gap: 2,
  },
  input: {
    backgroundColor: "white",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "black",
    border: 1,
    p: 1,
    color: "white",
  },
};

export default styles;
