import { useNavigate } from "react-router-dom";
import { request } from "../../api/utils";
import { useGetUser } from "../../store/selectors/userSelector";

/**
 * This hook contains the business logic needed for the SupplierTaskStatus component.
 *
 * @param taskId the ID of the task object this component is showing
 * @param workerId the ID of the worker who this task is assigned to
 * @returns the resources needed for the SupplierTaskStatus component
 */
const useSupplierTaskStatus = (
  taskId: string,
  workerId: string | undefined
) => {
  const navigate = useNavigate();
  const { userId } = useGetUser();

  /**
   * This method deletes a task object from the database. Afterwards it navigates the user back
   * to the Home Page.
   */
  const onDeleteTask = async () => {
    await request({
      requestType: "DELETE",
      endpoint: `/task`,
      body: { taskId, userId },
    });

    navigate("/home");
  };

  /**
   * This method approves a task object and updates the database. Afterwards it navigates
   * the user back to the Home Page.
   */
  const onApproveTask = async () => {
    await request({
      requestType: "PUT",
      endpoint: `/task/approve`,
      body: { taskId, userId },
    });

    navigate("/home");
  };

  /**
   * This method moves the task back into "in progress" status and updates the database. After
   * this call succeeds, we refresh the page to pull in the latest task information.
   */
  const onRequestRedoTask = async () => {
    await request({
      requestType: "PUT",
      endpoint: `/task/request-redo`,
      body: { taskId, userId },
    });

    window.location.reload();
  };

  /**
   * Navigates the user to the profile of the worker who this task is assigned to.
   */
  const onViewAssignedWorker = () => {
    if (workerId === undefined) return;
    navigate("/profile/" + workerId);
  };

  return {
    onDeleteTask,
    onApproveTask,
    onRequestRedoTask,
    onViewAssignedWorker,
  };
};

export default useSupplierTaskStatus;
