import { SxProps, Theme } from "@mui/material";
import Constants from "../../theme/constants";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    width: 200,
    height: 200,
    "&:hover": { cursor: "pointer" },
    p: 2,
    border: 1,
    borderColor: Constants.BORDER_COLOR,
    borderRadius: 2,
  },
};

export default styles;
