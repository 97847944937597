import { useGetUser } from "../../store/selectors/userSelector";
import { Navigate } from "react-router-dom";

/**
 * Wrapps a react component to require user authentication to view.
 *
 * @returns a wrapped component which requires user authentication to view `child`.
 */
const AuthWrapper = ({
  children,
}: {
  children: string | JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { userId } = useGetUser();
  const isAuthenticated = userId !== undefined;

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to="/login" />;
};

export default AuthWrapper;
