import { Box } from "@mui/material";
import { LayoutWrapperProps } from "./LayoutWrapper.data";
import styles from "./styles";
import BottomNavbar from "../../components/BottomNavbar";

/**
 * This wrapper is used to wrap the entire application layout. It adds a bottom navigation bar to each page.
 *
 * @returns a wrapped component with StockSwift's page layout.
 */
const LayoutWrapper = ({ children }: LayoutWrapperProps): JSX.Element => {
  return (
    <Box sx={styles.container}>
      <Box sx={{ flex: 1 }}>
        <Box sx={styles.childContainer}>{children}</Box>
      </Box>
      <BottomNavbar />
    </Box>
  );
};

export default LayoutWrapper;
