const GREEN = "#0BCE83";

const PURPLE = "#2D0C57";

const LIGHT_PURPLE = "#9586A8";

const BORDER_COLOR = "#D9D0E3";

const BACKGROUND_COLOR = "#F6F5F5";

export default { GREEN, PURPLE, LIGHT_PURPLE, BORDER_COLOR, BACKGROUND_COLOR };
