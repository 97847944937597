import { Box, Typography } from "@mui/material";
import { ImageUploaderProps, MAX_IMAGES_TO_UPLOAD } from "./ImageUploader.data";
import useImageUploader from "./ImageUploader.hooks";
import ImageUploading from "react-images-uploading";
import styles from "./styles";

/**
 * This component allows the user to upload photos from their native device. This allows users
 * to either select a photo from the library or take a new photo and use that. This component allows
 * users to upload multiple photos.
 *
 * @returns the element that represents this component
 */
const ImageUploader = ({
  images,
  setImages,
}: ImageUploaderProps): JSX.Element => {
  void useImageUploader(images, setImages);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.text}>
        {"Please upload images when you are done."}
      </Typography>
      <ImageUploading
        multiple
        value={images}
        onChange={setImages}
        maxNumber={MAX_IMAGES_TO_UPLOAD}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
        }) => (
          <Box sx={styles.contentContainer}>
            <Box sx={styles.buttonRowContainer}>
              <Box sx={styles.button} onClick={onImageUpload}>
                <Typography sx={styles.buttonText}>{"Upload Image"}</Typography>
              </Box>
              <Box sx={styles.button} onClick={onImageRemoveAll}>
                <Typography sx={styles.buttonText}>
                  {"Remove All Images"}
                </Typography>
              </Box>
            </Box>
            {imageList.map((image, index) => (
              <Box
                sx={styles.imageContentContainer}
                key={`submitted-image-${index}`}
              >
                <Box
                  component={"img"}
                  src={image.dataURL}
                  sx={styles.picture}
                />
                <Box sx={styles.buttonRowContainer}>
                  <Box sx={styles.button} onClick={() => onImageUpdate(index)}>
                    <Typography sx={styles.buttonText}>{"Update"}</Typography>
                  </Box>
                  <Box sx={styles.button} onClick={() => onImageRemove(index)}>
                    <Typography sx={styles.buttonText}>{"Remove"}</Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </ImageUploading>
    </Box>
  );
};

export default ImageUploader;
