import { useGetUser } from "../../store/selectors/userSelector";
import OpenTasksPage from "../OpenTasksPage";
import SupplierTaskPage from "../SupplierTaskPage";

/**
 * This page will be displayed when a user just authenticates themselves. It will hold either the
 * open tasks (if the user is a worker) or their created non-complete tasks (if the user is a supplier).
 *
 * Note: This might be a little confusing, and might warrant a refactor later. This "Home Page" is basically
 * just rendering a different "page". We can make the `SupplierTaskPage` and `OpenTasksPage` components instead
 * of pages later.
 *
 * @returns the component representing this page
 */
const HomePage = (): JSX.Element => {
  const { role } = useGetUser();

  if (role === "supplier") {
    return <SupplierTaskPage />;
  } else {
    return <OpenTasksPage />;
  }
};

export default HomePage;
