export type SignUpFormData = {
  name: string;
  username: string;
  password: string;
  phoneNumber: string;
  userType?: "supplier" | "worker";
};

export const defaultFormData: SignUpFormData = {
  name: "",
  username: "",
  password: "",
  phoneNumber: "",
  userType: undefined,
};

export const isValidForm = (data: SignUpFormData): boolean => {
  if (
    data === undefined ||
    data.name === "" ||
    data.username === "" ||
    data.password === "" ||
    data.phoneNumber === "" ||
    data.userType === undefined
  ) {
    return false;
  }

  return true;
};

export type SignUpFormProps = {
  onSwitchFormType: () => void;
};
