import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { request } from "../../api/utils";
import { useGetUser } from "../../store/selectors/userSelector";
import SupplierTaskStatus from "../../components/SupplierTaskStatus/SupplierTaskStatus";
import { TaskCardProps } from "../../components/TaskCard/TaskCard.data";
import WorkerTaskStatus from "../../components/WorkerTaskStatus";

/**
 * This page is used to view the task description and/or status of a given task. It will
 * render a different component based on the user's role (worker vs supplier).
 *
 * @returns the component representing this page
 */
const TaskDescriptionPage = (): JSX.Element => {
  const { role } = useGetUser();
  const [task, setTask] = useState<TaskCardProps>();
  const location = useLocation();

  useEffect(() => {
    const taskId = location.pathname.split("/").pop() as string;

    const fetchTask = async (taskId: string) => {
      try {
        const task = await request({
          requestType: "GET",
          endpoint: `/task`,
          queryParams: { taskId },
        });

        setTask({ ...task, taskId: task._id });
      } catch (e) {
        alert("There was an error fetching the task.");
        console.log(e);
      }
    };

    void fetchTask(taskId);
  }, []);

  if (task === undefined) {
    return <></>;
  }

  if (role === "supplier") {
    return <SupplierTaskStatus {...task} />;
  } else {
    return <WorkerTaskStatus {...task} />;
  }
};

export default TaskDescriptionPage;
