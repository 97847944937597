import { ImageListType } from "react-images-uploading";

/**
 * This hook contains the business logic for the ImageUploader component.
 *
 * @returns the resources needed by the ImageUpload component
 */
const useImageUploader = (
  images: ImageListType,
  setImages: React.Dispatch<React.SetStateAction<ImageListType>>
) => {};

export default useImageUploader;
