import { Box } from "@mui/material";
import styles from "./styles";
import useBottomNavbar from "./BottomNavbar.hooks";
import { BottomNavbarIcons } from "./BottomNavbar.data";

/**
 * This component is used to navigate between a user's profile page
 * and the rest of the application. It should displayed at the bottom of the screen.
 *
 * @returns the component for the bottom navigation bar
 */
const BottomNavbar = (): JSX.Element => {
  const { userIconSrc, gridIconSrc, onIconClick } = useBottomNavbar();

  return (
    <Box sx={styles.container}>
      <Box
        component={"img"}
        src={gridIconSrc}
        sx={styles.icon}
        onDragStart={(e) => e.preventDefault()}
        onClick={() => onIconClick(BottomNavbarIcons.GRID)}
      />
      <Box
        component={"img"}
        src={userIconSrc}
        sx={styles.icon}
        onDragStart={(e) => e.preventDefault()}
        onClick={() => onIconClick(BottomNavbarIcons.USER)}
      />
    </Box>
  );
};

export default BottomNavbar;
